export const tableProperties = [
  {
    label: '部门',
    prop: 'dept_name',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 120,
    fixed: 'left'
  },
  {
    label: '供应商简称',
    prop: 'supp_abbr',
    otherProp: 'suppAbbrList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 200,
    collapseTags: true,
    multiple: true,
    fixed: 'left'
  },
  {
    label: '年度总量',
    prop: 'yearTotalNum',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120,
    fixed: 'left',
    align: 'right'
  },
  {
    label: '年度总金额',
    prop: 'yearTotalPrice',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120,
    fixed: 'left',
    align: 'right'
  },
  {
    label: '一月总金额',
    prop: 'janPrice',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '一月总数量',
    prop: 'janNum',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '二月总金额',
    prop: 'febPrice',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '二月总数量',
    prop: 'febNum',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '三月总金额',
    prop: 'marPrice',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '三月总数量',
    prop: 'marNum',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '四月总金额',
    prop: 'aprPrice',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '四月总数量',
    prop: 'aprNum',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '五月总金额',
    prop: 'mayPrice',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '五月总数量',
    prop: 'mayNum',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '六月总金额',
    prop: 'junePrice',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '六月总数量',
    prop: 'juneNum',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '七月总金额',
    prop: 'julyPrice',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '七月总数量',
    prop: 'julyNum',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '八月总金额',
    prop: 'augPrice',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '八月总数量',
    prop: 'augNum',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '九月总金额',
    prop: 'septPrice',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '九月总数量',
    prop: 'septNum',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '十月总金额',
    prop: 'octPrice',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '十月总数量',
    prop: 'octNum',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '十一月总金额',
    prop: 'novPrice',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '十一月总数量',
    prop: 'novNum',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '十二月总金额',
    prop: 'decePrice',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '十二月总数量',
    prop: 'deceNum',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  }
];
